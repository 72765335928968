import { DynamicFormCriteriaFlowScope, FlowTypes } from "config";
import { push } from "connected-react-router";
import { useQueryParams } from "modules";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RootState, useAppDispatch } from "store";
import { getFinanceCustomerResultScreenPath, getMembershipMetadata } from "store/selectors";

// Redirect user to next page on Flow form submit
export const useFlowRouteRedirect = (slug: FlowTypes) => {
  // HOOKS
  const dispatch = useAppDispatch();
  const { state: locationState } = useLocation<{ from?: string }>();
  const { queryParams } = useQueryParams();
  const account = useSelector((state: RootState) => state.account);
  const { account_number, plaid_account_id } = useSelector(getMembershipMetadata);
  const cddFormRedirectPath = useSelector(getFinanceCustomerResultScreenPath);
  const flowScope = useSelector((state: RootState) => state.formFlow.flowScope);

  // DERIVED VARIABLES
  const isEditFlow = queryParams.get("edit") === "true";
  const hasExternalAccount = !!account_number || !!plaid_account_id;

  // CALLBACKS
  const beneficiaryFlowLink = useCallback(() => {
    const isApplicationFlowScope = flowScope === "application";
    const fallbackPaths: Record<DynamicFormCriteriaFlowScope, string> = {
      application: "/flow/aura-interview",
      policy_holder: "/products/insurance",
      finance: "/products/savings",
    };
    if (!flowScope) return "/";
    if (isApplicationFlowScope) return fallbackPaths.application;

    const fallbackPath = fallbackPaths[flowScope];
    return locationState?.from || fallbackPath;
  }, [flowScope, locationState]);

  // METHODS
  const getNextFlowPath = () => {
    switch (slug) {
      case "application":
        return "/flow/evidence-underwriting-aura-review";
      case "beneficiary":
        return beneficiaryFlowLink();
      case "aura-interview":
        return account.underwritingRequired
          ? "/products/insurance/confirm-update"
          : "/accept-terms";
      case "insurance-profile":
        return "/flow/beneficiary";
      case "savings-cdd":
        return cddFormRedirectPath;
      case "savings-default-application":
        const savingsFundingPath = hasExternalAccount
          ? "/products/savings/deposit/initial"
          : "/products/savings/deposit/get-started";
        return isEditFlow ? "/products/savings/application-summary" : savingsFundingPath;
      default:
        return "/";
    }
  };

  const redirectToNextPage = () => {
    const nextFlowPath = getNextFlowPath();

    dispatch(push(nextFlowPath));
  };

  return { redirectToNextPage };
};
