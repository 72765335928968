import Button from "components/Button/Button";
import { FaqUrl, IDocument } from "config";
import { find } from "lodash-es";
import { useTranslation } from "modules";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getDocumentsCategories } from "store/actions/documents";

interface IProps {
  document?: IDocument;
}

export const BeneficiaryReviewClaimsLink: FC<IProps> = ({ document: documentProp }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [document, setDocument] = useState<IDocument | undefined>(documentProp);
  const beneficiaryPacket = useAppSelector(state =>
    find(state.documents.documents, { slug: "beneficiary-packet" })
  );
  const flowScope = useAppSelector(state => state.formFlow.flowScope);

  useEffect(() => {
    if (!document) {
      if (beneficiaryPacket) {
        setDocument(beneficiaryPacket);
      } else {
        dispatch(getDocumentsCategories());
      }
    }
  }, [beneficiaryPacket, dispatch, document]);

  const documentLink = document?.html.url;
  const link = flowScope === "finance" ? FaqUrl : documentLink;
  if (!link) return null;

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <Button
        type="button"
        text={t("beneficiary.main.claims_instructions", "Get Claims Instructions")}
        className="full-width beneficiary-overview__claims-instructions-button"
        icon="DocumentDownload"
        align="left"
        subtype="text-dark"
      />
    </a>
  );
};
